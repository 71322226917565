import React from "react";
import { Menu, Transition } from "@headlessui/react";
import { Fragment } from "react";
import cn from "classnames";

export interface IDropdownProps {
  disabled?: boolean;
  trigger: any;
  items: any[];
  dropdownClassname?: string;
  dropdownDefaultItemClassName?: string;
  menuClassName?: string;
  className?: string;
  itemAs?: string;
  itemComponent?: (val: {
    last: boolean;
    item: any;
    active: boolean;
  }) => React.ReactNode;
  header?: any;
  footer?: any;
}

export const DropdownItem: React.FC<{
  item: { icon: any; name: string; onClick: () => void };
  className?: string;
}> = ({ item, className }) => {
  return (
    <div
      onClick={(e) => {
        e.stopPropagation();
        item.onClick();
      }}
      className={cn(
        "cursor-pointer rounded-md hover:bg-gray-20 p-2 body-100 flex items-center gap-2 whitespace-nowrap",
        className
      )}
    >
      {item.icon}
      {item.name}
    </div>
  );
};

const Dropdown = ({
  trigger,
  items,
  itemComponent,
  disabled,
  dropdownClassname,
  className,
  menuClassName,
  itemAs,
  header,
  footer,
  dropdownDefaultItemClassName,
}: IDropdownProps) => {
  return (
    <div className={className}>
      <Menu
        as="div"
        className={cn("relative inline-block text-left", menuClassName)}
      >
        <Menu.Button
          disabled={disabled}
          onClick={(e: any) => e.stopPropagation()}
          className="inline-flex justify-center w-full text-13 font-medium rounded-xl bg-opacity-20 hover:bg-opacity-30 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75"
        >
          {trigger}
        </Menu.Button>

        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items
            className={cn(
              "z-10 p-2 rounded-xl overflow-hidden m-h-44 overflow-y-auto absolute top-10 right-0 bg-white shadow-lg ring-1 ring-gray-900 ring-opacity-5 focus:outline-none max-h-[200px]",
              dropdownClassname
            )}
          >
            {header}
            {items.map((item, i: number) => {
              const last = i === items.length - 1;

              return (
                <Menu.Item as={itemAs} key={i}>
                  {({ active }) =>
                    item.customComponent ? (
                      item.customComponent()
                    ) : itemComponent ? (
                      itemComponent({ active, item, last })
                    ) : (
                      <DropdownItem
                        className={dropdownDefaultItemClassName}
                        item={item}
                      />
                    )
                  }
                </Menu.Item>
              );
            })}
            {footer}
          </Menu.Items>
        </Transition>
      </Menu>
    </div>
  );
};

export default Dropdown;
