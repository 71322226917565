import { AIDesignProjectType } from "api/AIDesignProjectApi";
import { CommentSubject } from "utils/constants";
import { IUserInfo } from "../contexts/UserContext";

export enum CommentStatusesEnum {
  ACTIVE = "active",
  RESOLVED = "resolved",
}
export class Comment {
  createdBy: IUserInfo;
  createdById: number;
  createdat: string;
  id: number;
  subject: CommentSubject;
  isViewed: boolean;
  mentions: number[];
  tvProject?: AIDesignProjectType;
  tvProjectId?: number | null;
  projectId?: number | null;
  tvGarmentRevisionId?: number | null;
  tvChannelId?: number | null;
  replies: this[];
  pageName: string;
  repliedToId: number | null;
  resourceData: Record<string, any>;
  resourcePath: string;
  status: CommentStatusesEnum;
  team: {
    id: number;
    description: string;
    name: string;
  };
  teamId: number;
  text: string;
  tvGarmentId: number;
  updatedat: string;
}
