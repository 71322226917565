import React from "react";
import cn from "classnames";
import ImageResponsive from "./ImageResponsive";

export interface IAvatarProps {
  image?: string | null;
  fullName?: string | null;
  className?: string;
  avatarColor?: string;
  badge?: boolean;
  style?: any;
  children?: any;
  imageClassName?: string;
}

const Avatar: React.FC<IAvatarProps> = ({
  image,
  fullName,
  className,
  avatarColor,
  badge = false,
  style,
  children,
  imageClassName,
}) => {
  const fullNameParts = (fullName || "user").split(" ").slice(0, 2);
  const initials = fullNameParts
    .map((el) => el.charAt(0).toUpperCase())
    .join("");

  return (
    <div
      style={{ backgroundColor: avatarColor, ...style }}
      className={cn(
        "avatar w-8 h-8 rounded-full flex items-center justify-center relative shrink-0",
        className,
        !image && "border-gray-40 border text-white text-bold"
      )}
    >
      {image ? (
        <ImageResponsive
          className={cn(
            "w-full h-full rounded-full object-cover",
            imageClassName
          )}
          url={image}
          alt="avatar"
          referrerPolicy="no-referrer"
        />
      ) : (
        <span className="uppercase leading-none mt-[1px]">{initials}</span>
      )}

      {badge && (
        <div className="bg-cta-alt-2 w-3 h-3 rounded-full absolute -top-1 -right-1"></div>
      )}
      {children}
    </div>
  );
};

export default Avatar;
