import React from "react";
import { TeamMember } from "types";
import { MentionsInput, Mention, MentionsInputProps } from "react-mentions";
import Avatar from "components/atoms/Avatar";
import { IUserInfo, useUser } from "contexts/UserContext";

export const MENTION_KEY = "@";

export const MENTION_START_PREFIX = "__MENTION";

export const MENTION_END_PREFIX = "MENTION__";

interface ICommentInputProps extends Partial<MentionsInputProps> {
  members: TeamMember[] | IUserInfo[];
}

export const SuggestionsContainer: React.FC = (children) => {
  return (
    <div className="py-2 border border-border-normal rounded-md">
      {children}
    </div>
  );
};

export const Suggestion: React.FC<{
  id: number;
  display: string;
  avatarColor: string;
}> = (item) => {
  return (
    <div className="p-1 flex items-center text-indigo-70 hover:bg-indigo-10 text-sm">
      <Avatar
        avatarColor={item.avatarColor}
        className="!w-6 !h-6 mr-2"
        fullName={item.display}
      />
      {item.display}
    </div>
  );
};

const CommentInput: React.FC<ICommentInputProps> = ({ members, ...props }) => {
  const { user } = useUser();

  return (
    <MentionsInput
      customSuggestionsContainer={SuggestionsContainer as any}
      allowSuggestionsAboveCursor
      className="comment-input"
      onClick={(e) => e.stopPropagation()}
      {...props}
    >
      <Mention
        renderSuggestion={Suggestion as any}
        trigger={MENTION_KEY}
        markup={
          MENTION_START_PREFIX +
          JSON.stringify(["__display__", "__id__"]) +
          MENTION_END_PREFIX
        }
        appendSpaceOnAdd
        data={members
          .filter((item) => item.id !== user?.id)
          .map((el) => ({
            id: el.id,
            display: el.name || el.email,
            avatarColor: el.avatarColor,
          }))}
      />
    </MentionsInput>
  );
};

export default CommentInput;
