import dayjs from "dayjs";
import getLocale from "get-user-locale";
import isEmpty from "lodash/isEmpty";

export const getLocaleDateFormat = ({
  date,
  userLocale,
  params = {},
}: {
  date: Date | string | null;
  userLocale?: string | null;
  params?: any;
}) => {
  if (!date) {
    return "N/A";
  }

  const locale = userLocale || getLocale();

  const options = isEmpty(params as object) ? { dateStyle: "medium" } : params;

  return new Intl.DateTimeFormat(locale, options).format(dayjs(date).toDate());
};
