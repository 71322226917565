import CtaButton, { Size, Variant } from "components/atoms/CtaButton";
import Dropdown, { IDropdownProps } from "components/atoms/DropDown";
import HorizontalThreeDotIcon from "public/icons/horizontal_three_dot.svg";
import Icon, { IconProps } from "components/atoms/Icon";
import React from "react";
import cn from "classnames";

interface IMoreActionsButtonProps extends Omit<IDropdownProps, "trigger"> {
  variant?: Variant;
  size?: Size;
  iconProps?: Omit<IconProps, "component">;
  triggerClassName?: string;
  dropdownClassName?: string;
}

const MoreActionsButton: React.FC<IMoreActionsButtonProps> = ({
  dropdownClassName,
  variant = "tetriary",
  size = "lg",
  iconProps = {},
  triggerClassName,
  ...props
}) => {
  return (
    <Dropdown
      dropdownClassname={cn("p-2 !divide-y-0", dropdownClassName)}
      trigger={
        <CtaButton
          size={size}
          variant={variant}
          className={cn("bg-transparent", triggerClassName)}
        >
          <Icon
            viewBox="0 0 448 512"
            component={HorizontalThreeDotIcon}
            className="fill-black"
            width={14}
            height={14}
            {...iconProps}
          />
        </CtaButton>
      }
      {...props}
    />
  );
};

export default MoreActionsButton;
