import {
  IAssignToUserPayload,
  IBillingPaginatedParams,
  IPaginatedBillingResponse,
  IPlanOfferPayload,
  IPromoCodePayload,
  ISpecialPlanOffer,
  PaymentPreview,
  PlanOffer,
  PromoCode,
} from "api/BillingApi";
import { useApi } from "contexts/ApiContext";
import BillingHistory from "models/BillingHistory";
import { useMutation, UseMutationOptions, UseQueryOptions } from "react-query";
import { useQuery } from "react-query";
import Stripe from "stripe";
import {
  BillingDebtsType,
  IBillingHistoryInvoiceResponse,
  IPaginateParams,
  PaginatedResponse,
} from "types";

export interface IChangeSubscriptionPayload {
  productId?: string;
  paymentMethodId?: string;
  additionalProducts?: any[];
  promoCodeId?: string;
  couponId?: string;
}

export const useGetPaymentPreview = ({
  payload,
  config,
}: {
  payload: any;
  config?: any;
}) => {
  const api = useApi();
  return useQuery<PaymentPreview>(
    ["payment-preview", payload],
    () => api.billing.getPaymentPreview(payload),
    config
  );
};

export const useGetBillingHistory = (
  params: {
    startingAfter?: string;
    endingBefore?: string;
    limit?: number;
  },
  config?: UseQueryOptions<any, any, any, any>
) => {
  const api = useApi();
  params.limit = params.limit ?? 10;
  return useQuery<{
    data: BillingHistory[];
    has_more: boolean;
    has_more_back: boolean;
    has_more_forward: boolean;
  }>(
    ["billing-history", params],
    () => api.billing.getPayments(params),
    config
  );
};

export const useGetInvoiceLines = (
  id: string,
  config?: UseQueryOptions<any, any, any, any>
) => {
  const api = useApi();
  return useQuery<IBillingHistoryInvoiceResponse>(
    ["invoice", id],
    () => api.billing.getInvoice(id),
    config
  );
};

export const useGetPaymentImagesPreview = (
  quantity: number,
  config?: UseQueryOptions<any, any, any, any>
) => {
  const api = useApi();
  return useQuery(
    ["payment-images-preview", quantity],
    () => api.billing.getPaymentImagesPreview({ quantity }),
    config
  );
};

export const useChargeImages = (
  config?: UseMutationOptions<any, any, any, any>
) => {
  const api = useApi();
  return useMutation(
    ({
      invoiceId,
      paymentMethodId,
    }: {
      invoiceId: string;
      paymentMethodId?: string;
    }) => api.billing.chargeImages({ invoiceId, paymentMethodId }),
    config
  );
};

export const useGetProducts = (
  config?: UseQueryOptions<any, any, any, any>
) => {
  const api = useApi();
  return useQuery<Stripe.Product[]>(
    ["products"],
    api.billing.getProducts,
    config
  );
};

export const useChangeSubscription = (
  config?: UseMutationOptions<any, any, any, any>
) => {
  const api = useApi();
  return useMutation(
    (payload: IChangeSubscriptionPayload) =>
      api.billing.changeSubscription(payload),
    config
  );
};

export const useAssignToUser = (
  config?: UseMutationOptions<any, any, any, any>
) => {
  const api = useApi();
  return useMutation(
    (payload: IAssignToUserPayload) => api.billing.assignToUser(payload),
    config
  );
};

export const useGetSubscription = (
  config?: UseQueryOptions<any, any, any, any>
) => {
  const api = useApi();
  return useQuery("subscription", () => api.billing.getSubscription(), config);
};

export const useGetPaymentMethods = (
  config?: UseQueryOptions<any, any, any, any>
) => {
  const api = useApi();
  return useQuery<Stripe.PaymentMethod[]>(
    "payment-methods",
    () => api.billing.paymentMethods(),
    config
  );
};

export const useAddPaymentMethod = (
  config?: UseMutationOptions<any, any, any, any>
) => {
  const api = useApi();
  return useMutation((id: string) => api.billing.addPaymentMethod(id), config);
};

export const useCancelSubscription = (
  config?: UseMutationOptions<any, any, any, any>
) => {
  const api = useApi();
  return useMutation(() => api.billing.cancelSubscription(), config);
};

export const useRemovePaymentMethod = (
  config?: UseMutationOptions<any, any, any, any>
) => {
  const api = useApi();
  return useMutation(
    (id: string) => api.billing.removePaymentMethod(id),
    config
  );
};

export const useSetDefaultPaymentMethod = (
  config?: UseMutationOptions<any, any, any, any>
) => {
  const api = useApi();
  return useMutation(
    (id: string) => api.billing.setDefaultPaymentMethod(id),
    config
  );
};

export const useGetSpecialPlanOffers = (
  params: IPaginateParams,
  config?: UseQueryOptions<any, any, any, any>
) => {
  const api = useApi();
  return useQuery<PaginatedResponse<ISpecialPlanOffer>>(
    ["special-plan-offers", params],
    () => api.billing.getSpecialPlanOffers(params),
    config
  );
};

export const useGetUpcomingInvoice = (
  config?: UseQueryOptions<any, any, any, any>
) => {
  const api = useApi();
  return useQuery(
    "upcoming-invoice",
    () => api.billing.getUpcomingInvoice(),
    config
  );
};

// promo-codes
export const useGetPromoCodes = (
  params: IBillingPaginatedParams,
  config?: UseQueryOptions<any, any, any, any>
) => {
  const api = useApi();
  params.limit = params.limit ?? 10;
  return useQuery<IPaginatedBillingResponse<PromoCode>>(
    ["promo-codes", params],
    () => api.billing.getPromoCodes(params),
    config
  );
};

export const useCreatePromoCode = (
  config?: UseMutationOptions<any, any, any, any>
) => {
  const api = useApi();
  return useMutation(
    (payload: IPromoCodePayload) => api.billing.createPromoCode(payload),
    config
  );
};

export const useDeletePromoCode = (
  config?: UseMutationOptions<any, any, any, any>
) => {
  const api = useApi();
  return useMutation((id: string) => api.billing.deletePromoCode(id), config);
};

export const useSetPromoCodeStatus = (
  config?: UseMutationOptions<any, any, any, any>
) => {
  const api = useApi();
  return useMutation(
    ({ id, isActive }: { id: string; isActive: boolean }) =>
      api.billing.setPromoCodeStatus(id, isActive),
    config
  );
};

export const useValidatePromoCode = (
  config?: UseMutationOptions<any, any, any, any>
) => {
  const api = useApi();
  return useMutation(
    (name: string) => api.billing.validatePromoCode({ name }),
    config
  );
};

export const useValidateRegisteredUserPromoCode = (
  config?: UseMutationOptions<any, any, any, any>
) => {
  const api = useApi();
  return useMutation(
    (payload: { name: string; productId?: string }) =>
      api.billing.validateRegisteredUserPromoCode(payload),
    config
  );
};

export const useCreatePlanOffer = (
  config?: UseMutationOptions<any, any, any, any>
) => {
  const api = useApi();
  return useMutation(
    (payload: IPlanOfferPayload) => api.billing.createPlanOffer(payload),
    config
  );
};

export const useGetPlanOffers = (
  params: IPaginateParams,
  config?: UseQueryOptions<any, any, any, any>
) => {
  const api = useApi();
  return useQuery<PaginatedResponse<PlanOffer>>(
    ["plan-offers", params],
    () => api.billing.getPlanOffers(params),
    config
  );
};

export const useGetUserPlanOffers = (
  config?: UseQueryOptions<any, any, any, any>
) => {
  const api = useApi();
  return useQuery<PlanOffer>(
    "user-plan-offers",
    () => api.billing.getUserPlanOffers(),
    config
  );
};

export const useSendUpgradeEmail = (
  config?: UseMutationOptions<any, any, any, any>
) => {
  const api = useApi();
  return useMutation(
    (message: string) => api.billing.sendUpgradeEmail(message),
    config
  );
};

export const usePayDebt = (config?: UseMutationOptions<any, any, any, any>) => {
  const api = useApi();
  return useMutation(() => api.billing.payDebt(), config);
};

export const useGetDebts = (config?: UseQueryOptions<any, any, any, any>) => {
  const api = useApi();
  return useQuery<BillingDebtsType>(
    ["billing-debts"],
    () => api.billing.getDebts(),
    config
  );
};
