import React, { useMemo } from "react";
import Highlighter from "react-highlight-words";
import { getParsedMentions } from "utils/getParsedMentions";
import cn from "classnames";

interface ICommentTextProps {
  text: string;
  className?: string;
}

const CommentText: React.FC<ICommentTextProps> = ({ text, className }) => {
  const transformedText = useMemo(() => {
    const data: { value: string; highlight: string[] } = {
      value: "",
      highlight: [],
    };

    if (text) {
      const { parsedString, mentionNames } = getParsedMentions(text);

      data.value = parsedString;
      data.highlight = mentionNames;
    }

    return data;
  }, [text]);

  return (
    <Highlighter
      highlightClassName="text-indigo-70 bg-transparent font-semibold"
      autoEscape
      textToHighlight={transformedText.value}
      searchWords={transformedText.highlight}
      className={cn("body-100 block", className)}
    />
  );
};

export default CommentText;
