import { UserModel } from "contexts/UserContext";
import { Comment, CommentStatusesEnum } from "models/Comment";
import useTranslation from "next-translate/useTranslation";
import React from "react";
import { useMemo } from "react";
import { success } from "utils/toast";
import MoreActionsButton from "../MoreActionsButton";
import cn from "classnames";
import { hasQueryParams } from "utils/hasQueryParams";
import { base64EncodeUnicode } from "utils/base64Util";
import PenIcon from "public/icons/pencil_16.svg";
import LinkIcon from "public/icons/link-chain.svg";
import TrashIcon from "public/icons/bin_16.svg";
import CheckIcon from "public/icons/check_12.71.svg";
import Icon from "components/atoms/Icon";
import { usePortal } from "contexts/PortalContext";

interface ICommentMoreActionsProps {
  comment: Comment;
  user: UserModel;
  onEdit: (comment: Comment) => void;
  onRemove: (comment: Comment) => void;
  onMarkAsRead: (comment: Comment) => void;
  onChangeStatus: (comment: Comment, status: CommentStatusesEnum) => void;
}

const CommentMoreActions: React.FC<ICommentMoreActionsProps> = ({
  comment,
  user,
  onEdit,
  onRemove,
  onMarkAsRead,
  onChangeStatus,
}) => {
  const { t } = useTranslation("common");
  const { portalName } = usePortal();

  const moreActions = useMemo(() => {
    const actions = [
      {
        icon: LinkIcon,
        label: t("copy_link"),
        viewBox: "0 0 28 28",
        onClick: async () => {
          const url =
            window.location.origin + `/${portalName}/comments/${comment.id}`;

          await window.navigator.clipboard.writeText(url);

          success({ message: t("link_copied") });
        },
      },
    ];

    if (user?.id === comment?.createdById) {
      actions.push(
        {
          icon: PenIcon,
          viewBox: "0 0 16 16",
          label: t("edit_comment"),
          onClick: () => onEdit(comment) as any,
        },
        {
          icon: TrashIcon,
          viewBox: "0 0 16 16",
          label: t("delete_comment"),
          onClick: () => onRemove(comment) as any,
        }
      );

      if (!comment?.repliedToId) {
        if (comment?.status !== CommentStatusesEnum.RESOLVED) {
          actions.push({
            icon: CheckIcon,
            viewBox: "0 0 12.71 12.71",
            label: t("mark_as_resolved"),
            onClick: () =>
              onChangeStatus(comment, CommentStatusesEnum.RESOLVED) as any,
          });
        } else {
          actions.push({
            icon: CheckIcon,
            viewBox: "0 0 12.71 12.71",
            label: t("mark_as_unresolved"),
            onClick: () =>
              onChangeStatus(comment, CommentStatusesEnum.ACTIVE) as any,
          });
        }
      }
    } else {
      if (!comment?.isViewed && !comment?.repliedToId) {
        actions.push({
          icon: CheckIcon,
          viewBox: "0 0 12.71 12.71",
          label: t("mark_as_read"),
          onClick: () => onMarkAsRead(comment) as any,
        });
      }
    }

    return actions;
  }, [user, comment]);

  return (
    <MoreActionsButton
      triggerClassName="hover:bg-gray-300 !p-2"
      iconProps={{
        className: "fill-black",
      }}
      items={moreActions}
      itemComponent={({ item }) => (
        <button
          className={cn(
            "w-full !px-2 !py-[5px] text-left !font-normal !justify-start !text-typography-body-normal hover:!bg-gray-20 text-sm rounded-md flex items-center gap-1 whitespace-nowrap"
          )}
          onClick={(e) => {
            e.stopPropagation();
            item.onClick();
          }}
        >
          {item.icon && (
            <Icon
              component={item.icon}
              viewBox={item.viewBox}
              className="w-4 h-4 fill-gray-70"
            />
          )}
          {item.label}
        </button>
      )}
    />
  );
};

export default CommentMoreActions;
