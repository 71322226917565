import { Form, useFormikContext } from "formik";
import React from "react";

const FormikForm = ({ children, className = "" }: any) => {
  const { submitForm } = useFormikContext();

  return (
    <Form
      className={className}
      onSubmit={(e) => {
        e.preventDefault();
        submitForm(); // https://github.com/jaredpalmer/formik/issues/2505#issuecomment-662493507
      }}
    >
      {children}
    </Form>
  );
};

export default FormikForm;
