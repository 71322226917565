import { useState } from "react";
import {
  useDeleteComment,
  useEditComment,
  useSetCommentStatus,
  useSetCommentViewStatus,
} from "./comments";
import { useErrorHandler } from "./useErrorHandler";
import useTranslation from "next-translate/useTranslation";
import { success } from "utils/toast";
import { Comment, CommentStatusesEnum } from "models/Comment";
import { getParsedMentions } from "utils/getParsedMentions";
import { UseMutationOptions } from "react-query";

export const useCommentManagement = (refetchComments: () => void) => {
  const { t } = useTranslation("common");
  const [deleteCandidate, setDeleteCandidate] = useState<Comment | null>(null);
  const [editCandidate, setEditCandidate] = useState<Comment | null>(null);
  const { handleError } = useErrorHandler();

  const onError = (err: any) => handleError({ err });

  const closeRemoveModal = () => setDeleteCandidate(null);

  const onRemoveComment = (comment: Comment) => setDeleteCandidate(comment);

  const { mutate: editComment, isLoading: editLoading } = useEditComment({
    onSuccess() {
      refetchComments();
      setEditCandidate(null);
      success({ message: t("comment_updated") });
    },
    onError,
  });

  const { mutate: removeComment, isLoading: removeLoading } = useDeleteComment({
    onSuccess() {
      refetchComments();
      closeRemoveModal();
      success({ message: t("comment_deleted") });
    },
    onError,
  });

  const { mutate: markAsRead } = useSetCommentViewStatus({
    onSuccess() {
      refetchComments();
    },
    onError,
  });

  const { mutate: changeStatus } = useSetCommentStatus({
    onSuccess(_, { status }) {
      refetchComments();
      success({ message: t("comment_resolved", { status }) });
    },
    onError,
  });

  const onEditComment = (text: string) => {
    const { mentionIds } = getParsedMentions(text);

    if (editCandidate) {
      editComment({
        id: editCandidate.id,
        payload: {
          text,
          mentionedUserIds: mentionIds,
        },
      });
    }
  };

  const onConfirmRemoving = (comment: Comment) => {
    removeComment(comment.id);
  };

  const onMarkAsRead = (
    comment: Comment,
    config?: UseMutationOptions<any, any, any>
  ) => {
    markAsRead(comment.id, config);
  };

  const onChangeStatus = (comment: Comment, status: CommentStatusesEnum) => {
    changeStatus({ id: comment.id, status });
  };

  return {
    deleteCandidate,
    editCandidate,
    setDeleteCandidate,
    setEditCandidate,
    closeRemoveModal,
    onRemoveComment,
    editLoading,
    removeLoading,
    onEditComment,
    onConfirmRemoving,
    onMarkAsRead,
    onChangeStatus,
  };
};
